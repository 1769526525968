@use '../common' as common;

.help {
  display: block;
  position: absolute;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);

  > .button {
    padding: 2px 0;
    padding-left: 3px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    > .button__icon {
      font-size: 1.25em;
      color: common.$light-blue;
    }
  }

  > .body {
    padding: 14px 21px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    > .close-icon {
      position: absolute;
      top: 15px;
      right: 20px;
      font-size: 1.65em;
      cursor: pointer;
    }

    > .message {
      display: flex;
      gap: 15px;
      align-items: center;

      > .icon-wrapper {
        width: 75px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: common.$light-grey;
      }
    }
  }
}
