.body {
  flex: auto;
  display: flex;

  > .pull-requests {
    display: flex;
    flex-direction: column;
    background-color: #fff;

    @media screen and (max-width: 1480px) {
      width: 400px;
    }

    @media screen and (min-width: 1480px) {
      width: 500px;
    }
  }

  > .developers-actions {
    flex: auto;
    display: flex;
    flex-direction: column;
  }
}
