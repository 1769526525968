@use './_common.scss' as *;

.dashboard-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.dashboard-wrapper {
  overflow: auto;
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $light-grey;
}

.dashboard {
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
