@use '../_common.scss' as common;

.account {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  max-width: common.$max-col-width;
  min-width: common.$min-col-width;

  > .name {
    color: common.$light-grey;
    font-size: 0.9em;
    margin-bottom: 2px;
    text-align: center;
  }

  > .pill {
    width: 90%;
    height: 4px;
    border-radius: 4px;

    &--cleared {
      background-color: common.$green;
    }

    &--has-tasks {
      background-color: common.$red;
    }
  }
}
