@use '../common' as common;

.badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  border-radius: 50%;
  font-size: 0.55em;
  background-color: common.$light-blue;
  color: #fff;
}

.eye {
  position: relative;

  > .eye__icon {
    font-size: 1.85em;
    color: #dcdcdc;
  }

  &--red {
    > .eye__icon {
      color: common.$red;
    }
  }

  &--with-badge {
    > .eye__icon {
      color: #fbc50e;
    }

    > .badge {
      top: -1px;
      right: -4px;
    }
  }
}

.check {
  position: relative;

  > .check__icon {
    color: common.$green;
  }

  &--with-badge {
    > .badge {
      top: -7px;
      right: -8px;
    }
  }
}

.close-icon {
  font-size: 1.4em;
  color: common.$red;
}

.list {
  position: relative;

  > .list__icon {
    font-size: 1.9em;
    color: common.$red;
  }

  > .badge {
    top: -5px;
    right: -7px;
  }
}

.sand-timer-icon {
  font-size: 1.9em;
  color: #b9b2b2;
}

.merge-icon {
  font-size: 2em;
  color: common.$red;
}

.question-mark {
  font-size: 2em;
  color: common.$red;
}
