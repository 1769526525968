@use '../_common.scss' as *;

.description-wrapper {
  height: $row-height;

  > .description {
    height: 100%;
    padding: 10px;
    background-color: #fefefe;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;

    > .description__left {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      gap: 20px;

      > .repository-name {
        border-radius: 10px;
        background-color: $light-grey;
        padding: 4px 8px;
        max-width: 220px;
        font-size: 0.8em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        color: $dark-grey;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    > .description__right {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 20px;

      > .title {
        text-decoration: none;
        font-size: 0.9em;
        color: rgb(24, 24, 24);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
          text-decoration: underline;
        }
      }

      > .updated-at-time {
        font-size: 0.7em;
        color: $dark-grey;
      }
    }
  }
}
