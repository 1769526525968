$max-col-width: 300px;
$min-col-width: 115px;
$row-height: 72px;

// colors
$light-grey: #f3f3f3;
$dark-grey: #3d3d3d;
$light-blue: rgb(20, 173, 231);
$red: rgb(231, 81, 115);
$green: rgb(5, 232, 117);
