@use '../../_common.scss' as *;

.actions {
  display: flex;
  height: $row-height;

  > .action {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: $max-col-width;
    min-width: $min-col-width;
  }
}
