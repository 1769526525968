@use '../_common.scss' as *;

.header {
  display: flex;
  background-color: $dark-grey;
  box-shadow: 0 0 6px black;
  position: relative;

  > .main {
    display: flex;
    padding: 10px 0;
    width: 100%;

    > .logo {
      padding: 0 10px;

      > .logo-img {
        width: 5em;
        margin: 0 10px;
      }

      @media screen and (max-width: 1480px) {
        width: 400px;
      }

      @media screen and (min-width: 1480px) {
        width: 500px;
      }
    }

    > .accounts {
      flex: 1 0 0;
      display: flex;
      align-items: flex-end;
    }
  }
}
